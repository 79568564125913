import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Button, Icon, Modal, Segment } from 'semantic-ui-react'

import Layout from "../components/layout"
import SEO from "../components/seo"
import headerImg from "../images/approve_annual_accounts_browserized_es.png"
import managementImg from "../images/nlive_meeting.gif"
import "./index.css"
import styled from "styled-components"

const Card = styled.div`
  padding: 10px !important;

  @media (min-width: 768px) {
    min-height: 300px !important;
  }

  h3 {
    margin: 20px 0;
    text-align: center;
  }
`

const Divider = styled.div`
  margin: 50px 0 !important;
`

const MainTitle = styled.h1`
    font-size: 48px;
    font-weight: bold;
`

const MainSubtitle = styled(MainTitle)`
    color: #2185D0;
`

const RaisedHeader = styled.h2`
    color: #2185D0;
`

const RaisedHeader3 = styled.h3`
    color: #2185D0;
`

const HomeSegment = styled.div`
    margin-top: -30px !important;
`

function nLiveEs({title, subtitle, description, showCallToAction}) {
  return (
    <Layout>
      <SEO
        title="nLive - votaciones en tiempo real"
        keywords={[`nvotes`, `seguro`, `online`, `votación`, `accionistas`, `asamblea`, `directo`, `tiempo real`, `en vivo`, `junta`, `juntas`, `asamblea`, `comité`, `dirección`, `comisión`, `voto`]}
      />

        <div className="ui vertically divided centered middle aligned stackable grid container">
          <div className="two column row home-main-segment">
            <HomeSegment className="column">
              <Segment vertical>
                  <MainSubtitle>{subtitle}</MainSubtitle>
                  <p>{description}</p>
                  {showCallToAction &&
                    <Link to="/contact">
                      <Button animated="fade" color="blue" size="huge">
                        <Button.Content visible>Comienza a organizar una votación</Button.Content>
                        <Button.Content hidden>
                            Comienza a organizar una votación &nbsp;
                          <Icon name='arrow right' />
                        </Button.Content>
                      </Button>
                    </Link>
                  }
                </Segment>
            </HomeSegment>
            <div className="column">
                <img src={headerImg} alt="online voting" />
            </div>
          </div>

            <div className="ui vertically divided centered middle aligned stackable grid container">
              <div className="four column row">
                <div className="column">
                    <div className="ui blue statistic">
                      <div className="value">
                        +2M
                      </div>
                      <div className="label">
                        votos emitidos
                      </div>
                    </div>
                </div>
                <div className="column">
                <div className="ui blue statistic">
                  <div className="value">
                    +150K
                  </div>
                  <div className="label">
                    votos en una votación
                  </div>
                </div>
              </div>
                <div className="column">
                  <div className="ui blue statistic">
                    <div className="value">
                      +150
                    </div>
                    <div className="label">
                      clientes
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="ui blue statistic">
                    <div className="value">
                      +700
                    </div>
                    <div className="label">
                      votaciones simultáneas
                    </div>
                  </div>
                </div>
              </div>
            </div>


          <div className="two column row home-data-row">
            <div className="column">
              <div className="ui raised segment" style={{padding: 0}}>
                <iframe
                  title="Voter experience video"
                  src="https://www.youtube.com/embed/HyT3LhYT4Zg?rel=0"
                  width="100%"
                  height="280px"
                  style={{margin: 0, border: 0}}
                  allowFullScreen="allowFullScreen">
                </iframe>
              </div>
            </div>
            <div className="column">
              <RaisedHeader><span>Experiencia del votante</span></RaisedHeader>
              <p>Los votantes se autentican desde cualquier dispositivo. Pueden emitir un voto en la pregunta actualmente activa con un simple clic. No hay nada más fácil.</p>
                <Modal closeIcon trigger={
                  <Button animated="fade" basic color="blue" size="big">
                    <Button.Content visible>View video</Button.Content>
                    <Button.Content hidden>
                      View video &nbsp;
                      <Icon name='arrow right' />
                    </Button.Content>
                  </Button>
                }>
                <Modal.Content style={{padding: 0}}>
                  <Modal.Description>
                    <iframe
                      title="voter experience video (same as before)"
                      src="https://www.youtube.com/embed/HyT3LhYT4Zg?autoplay=1&rel=0&enablejsapi=true"
                      width="100%"
                      height="600px"
                      frameborder="0"
                      style={{margin: 0, border: 0}}
                      allowFullScreen="allowFullScreen">
                    </iframe>
                  </Modal.Description>
                </Modal.Content>
              </Modal>
            </div>
          </div>
          <div className="two column row home-data-row">
            <div className="column">
              <RaisedHeader><span>Gestión de votaciones para todo el mundo</span></RaisedHeader>
              <p>La interfaz no se interpone en el camino. Puedes obtener resultados electorales en tiempo real. Puedes agregar una nueva pregunta en cualquier momento, incluso a mitad de una elección. nLives también te permite configurar diferentes pesos para cada votante. Y con un clic puedes ver un registro de auditoría detallado.</p>
            </div>
            <div className="column">
              <div className="ui raised segment">
                <Modal closeIcon trigger={
                  <img src={managementImg} alt="Online live voting" className="ui image" />
                }>
                <Modal.Content>
                  <Modal.Description>
                    <img src={managementImg} alt="Online Management Software" className="ui image massive" />
                  </Modal.Description>
                </Modal.Content>
              </Modal>
              </div>
            </div>
          </div>
        </div>
        <Divider className="ui divider"></Divider>

        <div className="ui centered middle aligned stackable grid container home-data-row">
          <RaisedHeader>Nuestros clientes</RaisedHeader>

          <p>
          Nuestro software es utilizado en todo el mundo en elecciones con hasta 2,7 millones de electores y más de 150,000 votos. &nbsp;
          <Link to="/customers/">Ver algunas referencias de clientes</Link>.
          </p>
          <div className="ui three column row">
            <div className="column">
              <Card className="ui fluid card">
                <div className="content">
                  <div className="header">
                    <RaisedHeader3>Asambleas y juntas</RaisedHeader3>
                  </div>
                  <div className="description">
                    <p>En su colegio profesional, empresa, federación o asociación, nLive puede ser una herramienta eficaz para votar de manera segura durante en una Asamblea General, Junta Directiva o Junta de Gobierno.</p>
                  </div>
                </div>
              </Card>
            </div>
            <div className="column">
              <Card className="ui fluid card">
                <div className="content">
                  <div className="header">
                    <RaisedHeader3>Partner de confianza</RaisedHeader3>
                  </div>
                  <div className="description">
                    <p>nLive puede ser su socio de confianza y proporcionar la plataforma de voto mientras usted brinda otros servicios de valor añadido al cliente como organizar el evento, configurar las elecciones o soporte al votante.</p>
                  </div>
                </div>
              </Card>
            </div>
            <div className="column">
              <Card className="ui fluid card">
                <div className="content">
                  <div className="header">
                    <RaisedHeader3>Otras organizaciones y necesidades</RaisedHeader3>
                  </div>
                  <div className="description">
                    <p>Sindicatos, cooperativas, escuelas.. también usan nLive para resolver sus necesidades de voto en vivo y para todo tipo de elecciones en línea o in-situ. También realizamos desarrollos y despliegues personalizados.</p>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
        <div className="ui hidden divider"></div>
        <div className="ui hidden divider"></div>
    </Layout>
  )
}

nLiveEs.defaultProps = {
  title: '',
  subtitle: 'Votaciones seguras online en reuniones',
  description: 'Justo lo que necesitas para votar.',
  showCallToAction: true
}

nLiveEs.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  showCallToAction: PropTypes.bool
}

export default nLiveEs
